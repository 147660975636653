<template>
  <div class="container mx-auto">
    <div
      class="
        text-left
        flex flex-col
        gap-6
        max-w-sm
        mx-auto
        p-6
        py-6
        mt-12
        border
        rounded-lg
        bg-white
      "
      v-if="!showSearchResults"
    >
      <h4>Enter your information below to get started!</h4>

      <div>
        <label>Household Last Name</label>
        <t-input
          placeholder="Enter Last Name"
          :variant="{
            error: isError('householdLastName'),
          }"
          v-model="form.householdLastName.value"
          name="household-last-name"
          @keyup.enter.native="lookupInfo"
        />
      </div>

      <div>
        <label>Phone Number</label>
        <t-input
          placeholder="(xxx) xxx-xxxx"
          :variant="{
            error: isError('phoneNumber'),
          }"
          v-model="form.phoneNumber.value"
          v-cleave="{
            numericOnly: true,
            prefix: '(',
            noImmediatePrefix: true,
            rawValueTrimPrefix: true,
            blocks: [4, 3, 4],
            delimiters: [') ', '-', '-'],
          }"
          @keyup.enter.native="lookupInfo"
          name="phone-number"
        />
      </div>

      <div>
        <label>Email</label>
        <t-input
          placeholder="myemail@email.com"
          :variant="{
            error: isError('email'),
          }"
          v-model="form.email.value"
          name="email"
          @keyup.enter.native="lookupInfo"
        />
      </div>

      <t-button @click="lookupInfo" v-if="!loading && preferences.enable_registration">Next</t-button>
      <t-button @click="lookupInfo" v-if="!preferences.enable_registration" disabled>Next</t-button>
      <t-button v-if="loading" disabled="disabled"
        ><font-awesome-icon
          :icon="['far', 'spinner']"
          class="fa-spin text-white"
      /></t-button>
    </div>

    <div
      v-if="showSearchResults"
      class="
        relative
        text-left
        flex flex-col
        gap-2
        w-96
        mx-auto
        p-6
        py-6
        mt-12
        border
        rounded-lg
        overflow-hidden
        bg-white
      "
    >
      <h4>Is any of this information yours?</h4>
      <h5 class="text-gray-400">
        We found a few records matching the info you entered. Select one and you will have an opportunity to update it.
      </h5>

      <div
        v-if="loading"
        class="
          absolute
          flex
          justify-center
          items-center
          left-0
          top-0
          w-full
          h-full
          bg-white bg-opacity-75
        "
      >
        <font-awesome-icon
          :icon="['far', 'spinner']"
          class="fa-spin text-gray-500 text-4xl"
        />
      </div>

      <ul class="flex flex-col gap-2 mt-4">
        <li v-for="(household, index) in filteredResults" :key="index">
          <a
            @click="searchResultsClick(index)"
            class="
              block
              bg-gray-100
              text-gray-800
              rounded
              p-4
              hover:bg-gray-200
              hover:no-underline
              cursor-pointer
              focus:border-blue-400
            "
          >
            <h5>{{ household.householdLastName }}</h5>
            <p class="text-sm">{{ household.phoneNumber }}</p>
            <p class="text-sm mt-1">{{ household.email }}</p>
            <p class="text-sm mt-1">
              {{ household.address1 }}, {{ household.addressCity }},
              {{ household.addressState }} {{ household.addressZip }}
            </p>
          </a>
        </li>
        <li>
          <a
            @click="searchResultsClick(null)"
            class="
              block
              bg-gray-100
              rounded
              p-4
              hover:bg-gray-200
              cursor-pointer
              focus:border-blue-400
            "
          >
            <h5>None of these is my information</h5>
          </a>
        </li>
      </ul>
    </div>

    <a
      class="inline-block mt-4 text-blue-500 cursor-pointer hover:underline"
      @click="restartForm"
      >Start Over</a
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import Airtable from "airtable";
const base = new Airtable({ apiKey: "patoTm7XJS3z3B9kb.d6a8954f9b42ee421609512a137d8c5924bc51122c510883bae54f6d23e0c7e5" }).base(
  "appcJIEiDAj4bYz1T"
);

const formFields = {
  householdLastName: {
    value: "",
    required: true,
    error: false,
  },
  phoneNumber: {
    value: "",
    validation: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
    required: true,
    error: false,
  },
  email: {
    value: "",
    validation:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    required: true,
    error: false,
  },
};

export default {
  name: "Lookup",
  data() {
    return {
      searchResults: [],
      filteredResults: [],
      showSearchResults: false,
      showErrors: false,
      loading: false,
      form: formFields,
    };
  },
  computed: {
    ...mapState(["preferences"]),
  },
  methods: {
    isError(field) {
      let hasError = false;
      this.checkForErrors(
        this.form[field].value,
        this.form[field].validation,
        this.form[field].required
      )
        ? (hasError = true)
        : null;
      this.form[field].error = hasError;

      // return hasError if showErrors is on, else return false
      return this.showErrors ? hasError : false;
    },
    checkForErrors(value, validation, required) {
      // if there's a validation, use that, otherwise check for blank field
      if (validation) {
        return validation.test(value) ? false : true;
      } else {
        if (required) {
          return value.length > 0 ? false : true;
        }
        return false;
      }
    },
    formHasErrors() {
      // returns true if any fields have errors
      let errors = false;
      for (let field of Object.keys(this.form)) {
        this.form[field].error ? (errors = true) : null;
      }
      return errors;
    },
    async searchResultsClick(id) {
      if (id !== null) {
        // load all appropriate information into the store
        this.loading = true;
        await this.$store.dispatch(
          "loadHousehold",
          this.searchResults[id].airtableID
        );
        // Set registration status to abandoned (in progress)

        if (
          this.$store.state.fields.registrationStatus.value !==
          "Registration Complete"
        ) {
          this.$store.dispatch("syncRegistrationStatus", {
            status: "Abandoned Registration",
            id: this.searchResults[id].airtableID,
          });
        }

        this.$store.commit("SET_NEW_REGISTRATION", false);

        // go to form page
        this.loading = false;
      } else {
        // if no results, go to registration page with information entered only
        this.$store.commit("UPDATE_FIELD_VALUE", {
          field: "householdLastName",
          newValue: this.form.householdLastName.value,
        });
        this.$store.commit("UPDATE_FIELD_VALUE", {
          field: "phoneNumber",
          newValue: this.form.phoneNumber.value,
        });
        this.$store.commit("UPDATE_FIELD_VALUE", {
          field: "email",
          newValue: this.form.email.value,
        });
      }

      this.$store.commit("SET_REGISTRATION_STARTED", true);
      this.$router.push("/register/form");
    },
    restartForm() {
      this.searchResults = [];
      this.showErrors = false;
      this.showSearchResults = false;
      //TODO: clear form entries
    },
    lookupInfo() {
      const self = this;

      // check for errors in form
      self.showErrors = true;

      // if there are no errors, lookup households
      if (!self.formHasErrors()) {
        self.loading = true;

        // run search in airtable households table
        let searchFormula = `SEARCH(
          "${self.form.householdLastName.value.toLowerCase()}",
          LOWER({Household Last Name})
        )`;

        base("Households")
          .select({
            view: "All Fields",
            filterByFormula: searchFormula,
          })
          .eachPage(
            function page(records, fetchNextPage) {
              // This function (`page`) will get called for each page of records.
              records.forEach(function (record) {
                // add to search results results
                self.searchResults.push({
                  airtableID: record.getId(),
                  id: record.get("ID"),
                  householdLastName: record.get("Household Last Name"),
                  phoneNumber: record.get("Phone Number"),
                  email: record.get("Email"),
                  address1: record.get("Address Line 1"),
                  address2: record.get("Address Line 2"),
                  addressCity: record.get("City"),
                  addressState: record.get("State"),
                  addressZip: record.get("Zip"),
                });
              });

              // To fetch the next page of records, call `fetchNextPage`.
              // If there are more records, `page` will get called again.
              // If there are no more records, `done` will get called.
              fetchNextPage();
            },
            async function done(err) {
              if (err) {
                console.error(err);
                new Error(err);
                return;
              }

              // Filter search results by phone or email first
              let filteredResults = self.searchResults.filter((result) => {
                return (
                  result.email === self.form.email.value ||
                  result.phoneNumber?.replace(/\D+/g, "") ===
                    self.form.phoneNumber.value.replace(/\D+/g, "")
                );
              });

              // if one email or phone number matches, load that one
              if (filteredResults.length == 1) {
                // load all appropriate information into the store
                self.loading = true;
                await self.$store.dispatch(
                  "loadHousehold",
                  filteredResults[0].airtableID
                );
                self.$store.commit("SET_NEW_REGISTRATION", false);

                // go to form page
                self.loading = false;

                if (
                  self.$store.state.fields.registrationStatus.value !==
                  "Registration Complete"
                ) {
                  // Set registration status to abandoned (in progress)
                  self.$store.dispatch("syncRegistrationStatus", {
                    status: "Abandoned Registration",
                    id: filteredResults[0].airtableID,
                  });
                }

                self.$store.commit("SET_REGISTRATION_STARTED", true);
                self.$router.push("/register/form");
                return;
              } else if (filteredResults.length > 1) {
                // if more than one result where phone or email matches, show all
                self.showSearchResults = true;
                self.loading = false;
                self.filteredResults = filteredResults;
              }

              if (!self.showSearchResults) {
                // TODO: create record in Airtable and set to Abandoned Registration
                self.$store.commit("CLEAR_FIELDS");

                // if no results, go to registration page with information entered only
                self.$store.commit("UPDATE_FIELD_VALUE", {
                  field: "householdLastName",
                  newValue: self.form.householdLastName.value,
                });
                self.$store.commit("UPDATE_FIELD_VALUE", {
                  field: "phoneNumber",
                  newValue: self.form.phoneNumber.value,
                });
                self.$store.commit("UPDATE_FIELD_VALUE", {
                  field: "email",
                  newValue: self.form.email.value,
                });

                self.$store.commit("SET_REGISTRATION_STARTED", true);
                self.$router.push("/register/form");
                return;
              }
            }
          );
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>